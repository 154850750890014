// extracted by mini-css-extract-plugin
export var category = "Feed-module--category--59f58";
export var description = "Feed-module--description--57348";
export var divider = "Feed-module--divider--81a18";
export var feed = "Feed-module--feed--a6204";
export var item = "Feed-module--item--c7a63";
export var link = "Feed-module--link--6123b";
export var meta = "Feed-module--meta--250c2";
export var more = "Feed-module--more--51a4e";
export var time = "Feed-module--time--72864";
export var title = "Feed-module--title--f252f";